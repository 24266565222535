import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import VueCookies from 'vue-cookies'
import { createGtm } from '@gtm-support/vue-gtm'
import * as Sentry from '@sentry/vue'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        console.log(import.meta.env.VITE_SENTRY_DSN_PUBLIC)

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.APP_ENV,
            tracesSampleRate: 1.0,
        })

        app.use(plugin)
            .use(autoAnimatePlugin)
            .use(ZiggyVue)
            .use(VueCookies, {
                expireTimes: '7d',
            })
            .use(
                createGtm({
                    id: 'GTM-TGXS5TD4',
                    enabled: false,
                    debug: true,
                })
            )
            .mount(el)

        return app
    },
    progress: {
        color: '#4B5563',
    },
})
